@import url('https://fonts.googleapis.com/css2?family=Inter+Tight:ital,wght@0,200;1,300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Moon+Dance&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abril+Fatface&display=swap');

html, body, #root {
  min-height: 100%;
  height: 100%;
  scroll-behavior: smooth;
  font-family: 'Inter Tight', sans-serif;
  overflow-x: hidden;
  cursor: none;
}

.htmlTags {
  font-family: 'Moon Dance', cursive;
  font-size: 1.5em;
  opacity: 0.4;
}

.mainFont {
  font-family: 'Abril Fatface', cursive;
}

#logo {
  width: 15%;
  display: inline-block;
}

@media (min-width:640px) {
  #logo {
    width: 10%;
    display: inline-block
  }
}

#nav-links {
  display: none;
}

@media (min-width:640px) {
    #nav-links {
    // hidden sm:flex flex-row w-[40%] items-center justify-end
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    li {
      margin-left: 5%;
      margin-right: 5%;
      min-height: 50px;
      display: flex;
      align-items: center;
      a {
        font-size: 1.3em;
      }
    }
  }
}

#detail {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

@media (min-width:640px) {
  #detail {
    display: flex;
    flex-direction: column;
    #picture-detail{
      display: flex;
      flex-direction: row;
      width: 100%;
      #profile-picture {
        padding: 5%;
        width: 100%;
        max-height: 500px;
        padding-top: 35%;
        padding-right: 15%;
        img {
          max-width: 100%;
          height: auto;
          // border-radius: 50%;
        }
      }
      #detail-text {
        width: 100%;
        padding: 5%;
      }
      li {
        margin-left: 2%;
        margin-right: 2%;
      }
    }
    #tech-stack {
      display: flex;
      flex-direction: column;
      align-items: center;
      p {
        margin-right: 7%;
      }
      li {
        width: 100%;
        padding-right: 10%;
        font-size: 1.3em;
      }
    }
  }
  #projects {
    display: flex;
    flex-direction: column;
    #project-gallery {
      display: flex;
      flex-direction: column;
      .project-detail {
        display: flex;
        flex-direction: row;
      }
    }
  }
}

a {
  cursor: none;
}

#social-line {
  margin-top: 5%;
  margin-left: 0.8em;
  border-left: 6px solid white;
  height: 250px;
}

.hoverButtons {
  transition: all 1.7s;
  cursor: none;
}

.hoverButtons:hover {
  transform:scale(1.2);
  transition: all 0.5s;
}

// #followCursor {
//   position: absolute;
//   top: 0;
//   left: 0;
//   width: 30px;
//   height: 30px;
//   border-radius: 100%;
//   border: solid 1px white;
//   transform: translate(-50%, -50%);
//   -webkit-transition-duration: 300ms;
//   transition-duration: 300ms;
//   -webkit-transition-timing-function: ease-out;
//   transition-timing-function: ease-out;
//   will-change: width, height, transform, border;
//   z-index: 999;
//   pointer-events: none;

// }